<template>
  <div class="calendar-list-view">
    <HubEventsList
      :name="'past-events'"
      :title="past.title"
      :items="past.items"
      :buttonCaption="$t('message.common.buttons.morePastEvents')"
      v-if="hasPastItems"
    />

    <HubEventsList
      :name="'upcoming-events'"
      :title="upcoming.title"
      :items="upcoming.items"
      :buttonCaption="$t('message.common.buttons.moreUpcomingEvents')"
      v-if="hasUpcomingItems"
    />

    <CalendarLegend
      :items="legendItems"
      :title="$t(`message.calendar.legend`)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HubEventsList from '@blocks/hubs/HubEventsList'
import updateBreadcrumb from '@mixins/updateBreadcrumb'
import CalendarLegend from '@blocks/calendar/CalendarLegend'
import legendItems from '@mixins/legendItems'

export default {
  name: 'CalendarListView',
  mixins: [ updateBreadcrumb, legendItems ],
  components: {
    HubEventsList,
    CalendarLegend
  },
  computed: {
    ...mapGetters({
      past: 'events/getPast',
      upcoming: 'events/getUpcoming'
    }),
    hasPastItems () {
      return this.past.items.length
    },
    hasUpcomingItems () {
      return this.upcoming.items.length
    }
  }
}
</script>
<style lang="scss">
.calendar-list-view {}
</style>
